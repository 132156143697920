
import { getCategoryList } from '@/api';
import { ref } from 'vue';
import router from '@/router';
import store from "@/store";
export default {
    setup(){
        const categoryList = ref();
        store.dispatch('isLoading');
        getCategoryList().then((res: any) => {
            categoryList.value = res.data; 
            store.dispatch('notLoading');
        });
        const handleCardClick = (id,articleCount) => {
            if(articleCount > 0){
                router.push('/categories/' + id);
            }
                
        }
        return {
            categoryList,
            handleCardClick,
        };
    }

}
